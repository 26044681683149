/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../../components/pl/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Fotogalerie"}>
        <SiteHeader />

        <SiteHeader />

        <Column className="--center pb--80 pt--80" name={"wstęp"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--62" content={"<span style=\"color: var(--color-dominant);\">Galeria</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"kontakt-0"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--4" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Torty lodowe"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Malinowy z polewą\n"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/135/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Czekoladowy z polewą"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Waniliowy z owocami"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 mt--30" columns={"4"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":800}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Kolorowy dla dzieci\n"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/135/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Lekki tort Sachera "}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Porzeczkowy sorbet"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":800}}>
              
              <Image alt={"Kliknij, aby edytować obraz"} src={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/135/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/135/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/135/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/135/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/135/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Ciasto z orzechami włoskimi\n"}>
              </Subtitle>

              <Text className="text-box" content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-es5uly pb--60 pt--60" name={"kontakt"} layout={"l1"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/135/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="pb--10 pt--10">
              
              <Title className="title-box fs--62 lh--1 mt--08" style={{"maxWidth":1276}} content={"<span style=\"color: rgb(255, 255, 255);\">Masz ochotę na ciasto?</span>"}>
              </Title>

              <Button className="btn-box" content={"Kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"stopka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 el--3" anim={"2"} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Cukiernia<br>& Piekarnia</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. Niektóre elementy zawierają specjalne funkcje."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Pańska 18</span><br>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Kliknij i zacznij pisać, to pole jest przeznaczone na tekst. W razie potrzeby można zmienić kolor bloku tekstu, wielkość liter, odstępy między wierszami czy wyjustować."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Kontakt</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"+48 797 811 2X0<br>info@twoje-stronyy.com"}>
              </Text>

              <Text className="text-box" content={"REGON: 12345678<br>Utworzone przez<a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}